import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import styled from "styled-components"

const StyledContainer = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding: 1.45rem 1.0875rem;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <StyledContainer>
      <p>
        Bada Bing is a premier online retailer and serves major markets around
        the world.
      </p>{" "}
      <p>We manage a diverse portfolio of products and platforms.</p>{" "}
      <p>
        Partnerships with Amazon and eBay allow us to offer your products to the
        largest consumer base available anywhere.
      </p>
    </StyledContainer>
  </Layout>
)

export default IndexPage
